<template>
  <div class="registerBox">
    <div class="backTitle">
      <div @click="back()"></div>
      <div>忘记密码</div>
    </div>
    <div class="h3Size">找回密码</div>
    <div class="promptSize">请输入新密码并验证手机号</div>
    <div class="formInput">
      <div class="mobileStyle">
        <input class="mobileInp" v-model="regForm.mobile" type="text" placeholder="请输入手机号" />
      </div>
      <div class="CodeStyle">
        <input class="CodeInp" v-model="regForm.code" type="text" placeholder="请输入验证码" />
        <div class="codeDiv" v-if="isShow" @click="obtainCode()">获取验证码</div>
        <div class="codeColor" v-if="!isShow">{{this.count}}s重新获取</div>
      </div>
      <div class="CodeStyle">
        <input
          class="CodeInp"
          ref="type"
          v-model="regForm.password"
          type="password"
          placeholder="请输入新密码"
        />
        <div class="showPassword">
          <img @click="inpType()" v-if="flag" src="../image/show1.png" alt />
          <img @click="inpType1()" v-if="!flag" src="../image/show.png" alt />
        </div>
      </div>
    </div>
    <div class="loginBtn" v-if="btnStatus">完成</div>
    <div class="loginBtn1" v-if="!btnStatus" @click="loginEvent()">完成</div>
    <div class="footer">
      登录表示同意
      <span>《隐私政策》</span> 和
      <span>《服务协议》</span>
    </div>
  </div>
</template>

<script>
import { send, loginUser, userSetpwd } from "../api/user";
import { Toast } from "vant";
export default {
  data() {
    return {
      isShow: true, //通过 v-show 控制显示'获取按钮'还是'倒计时'
      regForm: {
        mobile: "", //手机号
        password: "", //密码
        code: "", //验证码
        openId: "" //微信
      },
      btnStatus: true,
      flag: true
    };
  },
  watch: {
    "regForm.mobile"(newValue) {
      if (newValue.trim() == "") {
        this.btnStatus = true;
      }
    },
    "regForm.code"(newValue) {
      if (newValue.trim() == "") {
        this.btnStatus = true;
      }
    },
    "regForm.password"(newValue) {
      if (newValue.trim() == "") {
        this.btnStatus = true;
      } else {
        this.btnStatus = false;
      }
    }
  },
  mounted() {},
  methods: {
    //   获取验证码
    obtainCode() {
      const phoneReg = /^1[3456789]\d{9}$/;
      if (!phoneReg.test(this.regForm.mobile)) {
        Toast("请输入正确的手机号");
      } else {
        this.isShow = false;
        this.count = 5;
        var times = setInterval(() => {
          this.count--; //递减
          if (this.count <= 0) {
            // <=0 变成获取按钮
            this.isShow = true;
            clearInterval(times);
          }
        }, 1000); //1000毫秒后执行
        send({ mobile: this.regForm.mobile, type: 3 }).then(res => {
          console.log(res, "注册获取验证码");
        });
      }
    },
    // 登录
    loginEvent() {
      userSetpwd(this.regForm).then(res => {
        console.log(res, "找回密码");
        if (res.data.code === 0) {
          Toast("请重新登录");
          this.$router.go(-1);
        } else {
          Toast(res.data.msg);
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    inpType() {
      this.flag = false;
      this.$refs.type.type = "text";
    },
    inpType1() {
      this.flag = true;
      this.$refs.type.type = "password";
    }
  }
};
</script>

<style scoped>
.backTitle {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.backTitle div:nth-child(1) {
  width: 48px;
  height: 48px;
  background-image: url("../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.backTitle div:nth-child(2) {
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 232px;
}
.h3Size {
  font-size: 48px;
  font-weight: 400;
  color: #3d3d3d;
  margin-left: 32px;
  margin-top: 140px;
}

.promptSize {
  font-size: 32px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 32px;
  margin-left: 32px;
}
.formInput {
  margin-top: 30px;
}
.mobileStyle,
.CodeStyle {
  width: 686px;
  border-bottom: 2px solid #ebedf0;
  margin-left: 32px;
}
.mobileInp {
  outline: none;
  border: none;
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 32px;
  margin-top: 64px;
  width: 686px;
}
.CodeStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CodeInp {
  outline: none;
  border: none;
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 32px;
  margin-top: 32px;
  width: 508px;
}
.codeDiv {
  font-size: 28px;
  font-weight: 400;
  color: #0957c3;
}
.codeColor {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}
.showPassword img {
  width: 44px;
  height: 44px;
}
.loginBtn,
.loginBtn1 {
  width: 684px;
  height: 90px;
  background-color: #a2b3f3;
  border-radius: 1000px 1000px 1000px 1000px;
  text-align: center;
  line-height: 90px;
  opacity: 1;
  font-size: 36px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 202px;
  margin-left: 32px;
}
.loginBtn1 {
  background: #0957c3;
  border-radius: 1000px 1000px 1000px 1000px;
  opacity: 1;
}
.footer {
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 76px;
  position: fixed;
  bottom: 86px;
  margin-left: 145px;
}
.footer span {
  display: inline-block;
  color: #0957c3;
}
</style>
